<template>
  <div class="award-detail">
    <Head :head-info="headInfo" @headBack="goBack" @headRule="handleShowRule"></Head>
    <div :class="[joinAward ? 'head-active' : '','award-head']">
      <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_award_coupon.png" alt="">
      <div class="coupon-inner">
        <div v-if="countDownTime > 0" class="amount">
          <van-count-down :time="countDownTime">
            <template #default="timeData">
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
            </template>
          </van-count-down>
          <p>后开奖</p>
        </div>
        <p v-else>{{ luckyDate }} 期开奖码</p>
        <div v-if="hasPub" class="reward-ball">
          <template v-for="(item, index) in rewardBall">
            <div :key="index">
              <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_reward_ball.png">
              <p>{{ item }}</p>
            </div>
          </template>
        </div>
        <div v-else class="no-pub">
          <img
            :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/reward_${pubDate ? 'tomorrow' : 'today'}_open.png`">
        </div>
        <p class="delay-city">开奖码基于7个城市拥堵延时指数计算所得</p>
      </div>
      <transition name="spread">
        <div v-show="isSpread" class="spread-part">
          <template v-for="item in cityList">
            <transition name="show">
              <div v-show="isSpread" :key="item.code">
                <p>{{ item.city }}</p>
                <p>{{ hasPub ? item.data : '?' }}</p>
              </div>
            </transition>
          </template>
        </div>
      </transition>
      <div class="arrow-down">
        <van-icon v-if="!isSpread" name="arrow-down" @click="handleSpread"/>
        <van-icon v-else name="arrow-up" @click="handleSpread"/>
      </div>
    </div>
    <div v-if="joinAward" class="award-win">
      <img v-if="winningList.length > 0"
           src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_award_amount.png">
      <p v-if="winningList.length > 0" class="winning-amount">
        {{ drawInfo.prizeCount > 0 ? `本期 ${drawInfo.prizeCount} 人中奖` : '中奖明细' }}</p>
      <div class="winning-goods-list" v-if="winningList.length > 0">
        <template v-for="item in winningList">
          <div class="content winning-content" :key="item.id">
            <img :src="item.itemPic">
            <div class="info">
              <p class="title-1">{{ item.itemTitle }}</p>
              <p>商品价值<span></span> ￥{{ item.itemPrice }}</p>
              <p>抢购金额<span></span> ￥{{ item.costAmount }}</p>
              <p class="winning">中奖用户 {{ item.phone }}</p>
            </div>
          </div>
        </template>
      </div>
      <p v-if="winningGoodsList.length > 0" class="award-marked">恭喜您！以下参与中奖啦</p>
      <div>
        <template v-for="item in winningGoodsList">
          <div class="purchase-goods-list" :key="item.id">
            <div class="head">
              <span>{{ item.luckyDate }}期</span>
              <span>{{ item.status == 1 ? drawTime(item) : `开奖码 ${item.luckyCode}` }}</span>
            </div>
            <div class="content">
              <img :src="item.itemPic">
              <div class="info">
                <p class="title">{{ item.itemTitle }}</p>
                <p>商品价值<span></span> ￥{{ item.itemPrice }}</p>
                <p>抢购金额<span></span> ￥{{ item.costAmount }}</p>
                <p>参与时间<span></span> {{ item.createTime }}</p>
              </div>
            </div>
            <div class="code-list">
              <p>我的抽奖码</p>
              <div>
                <template v-for="(item_c, index_c) in item.codeList">
                  <div class="code-wrap" :key="index_c">
                    <p>{{ item_c }}</p>
                  </div>
                </template>
              </div>
            </div>
            <div class="footer-button" @click="handleRecordClick(item)">
              <p
                :class="item.status == 1 && (item.codeList.length < item.codeLimit) ? 'button-more' : item.status == 3 ? 'button-earn' : item.status == 2 ? 'button-recharge' : item.status == 6 ? 'button-recharge' :  'normal'">
                {{
                  item.status == 1 && (item.codeList.length < item.codeLimit) ? '再拿一个' : item.status == 3 ? '立即领奖' : item.status == 2 ? '申请退款' : item.status == 6 ? '我的红包' : item.status == 4 ? '已领奖' : item.status == 5 ? '已退款' : item.status == 7 ? '已过期' : ''
                }}</p>
            </div>
          </div>
        </template>
      </div>
      <p v-if="loseGoodsList.length > 0" class="award-marked">很遗憾，以下参与未中奖</p>
      <div>
        <template v-for="item in loseGoodsList">
          <div class="purchase-goods-list" :key="item.id">
            <div class="head">
              <span>{{ item.luckyDate }}期</span>
              <span>{{ item.status == 1 ? drawTime(item) : `开奖码 ${item.luckyCode}` }}</span>
            </div>
            <div class="content">
              <img :src="item.itemPic">
              <div class="info">
                <p class="title">{{ item.itemTitle }}</p>
                <p>商品价值<span></span> ￥{{ item.itemPrice }}</p>
                <p>抢购金额<span></span> ￥{{ item.costAmount }}</p>
                <p>参与时间<span></span> {{ item.createTime }}</p>
              </div>
            </div>
            <div class="code-list">
              <p>我的抽奖码</p>
              <div>
                <template v-for="(item_c, index_c) in item.codeList">
                  <div class="code-wrap" :key="index_c">
                    <p>{{ item_c }}</p>
                  </div>
                </template>
              </div>
            </div>
            <div class="footer-button" @click="handleRecordClick(item)">
              <p
                :class="item.status == 1 && (item.codeList.length < item.codeLimit) ? 'button-more' : item.status == 3 ? 'button-earn' : item.status == 2 ? 'button-recharge' : item.status == 6 ? 'button-recharge' :  'normal'">
                {{
                  item.status == 1 && (item.codeList.length < item.codeLimit) ? '再拿一个' : item.status == 3 ? '立即领奖' : item.status == 2 ? '申请退款' : item.status == 6 ? '我的红包' : item.status == 4 ? '已领奖' : item.status == 5 ? '已退款' : item.status == 7 ? '已过期' : ''
                }}</p>
            </div>
          </div>
        </template>
      </div>
      <p v-if="drawRecordList.length > 0" class="award-marked">我的参与</p>
      <div>
        <template v-for="item in drawRecordList">
          <div class="purchase-goods-list" :key="item.id">
            <div class="head">
              <span>{{ item.luckyDate }}期</span>
              <span>{{ item.status == 1 ? drawTime(item) : `开奖码 ${item.luckyCode}` }}</span>
            </div>
            <div class="content">
              <img :src="item.itemPic">
              <div class="info">
                <p class="title">{{ item.itemTitle }}</p>
                <p>商品价值<span></span> ￥{{ item.itemPrice }}</p>
                <p>抢购金额<span></span> ￥{{ item.costAmount }}</p>
                <p>参与时间<span></span> {{ item.createTime }}</p>
              </div>
            </div>
            <div class="code-list">
              <p>我的抽奖码</p>
              <div>
                <template v-for="(item_c, index_c) in item.codeList">
                  <div class="code-wrap" :key="index_c">
                    <p>{{ item_c }}</p>
                  </div>
                </template>
              </div>
            </div>
            <div class="footer-button" @click="handleRecordClick(item)">
              <p
                :class="item.status == 1 && (item.codeList.length < item.codeLimit) ? 'button-more' : item.status == 3 ? 'button-earn' : item.status == 2 ? 'button-recharge' : item.status == 6 ? 'button-recharge' :  'normal'">
                {{
                  item.status == 1 && (item.codeList.length < item.codeLimit) ? '再拿一个' : item.status == 3 ? '立即领奖' : item.status == 2 ? '申请退款' : item.status == 6 ? '我的红包' : item.status == 4 ? '已领奖' : item.status == 5 ? '已退款' : item.status == 7 ? '已过期' : ''
                }}</p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="award-default">
      <p>抱歉，您未参与本期抽奖活动</p>
      <div>
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_goods_none.png">
        <p class="join-activity" @click="handleJoinActivity">立即参与活动</p>
      </div>
    </div>
    <van-dialog v-model="dialogVisible" :showConfirmButton="false" style="background-color: transparent;">
      <div class="dilate-packet">
        <img class="packet-title"
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_text_refund.png">
        <img class="packet-coupon"
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_packet_refund.png">
        <div class="packet-text">
          <p>可将未中奖的1元</p>
          <p>立即膨胀为30元购物红包</p>
        </div>
        <div class="packet-button">
          <div class="button" @click="handleDialogSubmit">
            <p>立即领取</p>
            <van-icon name="play"/>
          </div>
          <p @click="handleCloseDialog">不要福利，立即退款</p>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { CountDown, Icon, Dialog } from 'vant'
import { timeFormat } from '@/utils/utils'
import baseUrl from '@/utils/baseUrl'
import Head from '../../components/fullWebviewHead.vue'

const api = require('@/utils/api').api
Vue.use(Icon)
Vue.use(CountDown)
Vue.use(Dialog)

export default {
  name: 'awardDetail',
  data () {
    return {
      //中奖信息
      drawInfo: {},
      // 开奖期数
      luckyDate: '',
      //开奖码
      rewardBall: [],
      //头部返回
      headInfo: {},
      //是否参与抽奖
      joinAward: true,
      // 中奖名单
      winningList: [],
      // 中奖商品列表
      winningGoodsList: [],
      // 参与未中奖
      loseGoodsList: [],
      //用户参与
      drawRecordList: [],
      // 城市延时
      cityList: [],
      //展开
      isSpread: false,
      // 开奖倒计时
      countDownTime: 0,
      //开奖日期
      pubDate: 0,
      //id
      recordId: '',
      // 是否开奖
      hasPub: false,
      dialogVisible: false,
    }
  },
  components: {
    Head
  },
  created () {
    this.luckyDate = this.$route.query.luckyDate
  },
  mounted () {
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
    if (this.$store.state.isAndroidApp) {
      this.$h5AppLink('updateStyle', {
        statusBarBgColor: '#F74545',
        statusBarTextColor: 'white'
      })
    }
    this.handleCheckIsOpenDraw()
    this.handleGetDetail()
  },
  computed: {
    // computed：注意不能直接在photoList后面加参数，没效果（应该是vue不支持），应该以JavaScript闭包的形式
    drawTime () {
      return function (data) {
        const nowTime = timeFormat(new Date()).slice(0, 10)
        const pubTime = timeFormat(data.pubTime).slice(0, 10)
        return pubTime > nowTime ? `明日${data.drawTime}开奖` : `今日${data.drawTime}开奖`
      }
    }
  },
  methods: {
    /*
    展开城市
     */
    handleSpread () {
      this.isSpread = !this.isSpread
    },

    /*
    判断开奖date
    */
    handleCheckIsOpenDraw () {
      this.headInfo = {
        title: '开奖详情',
        rightText: '规则',
        fontColor: '#ffffff',
        bgColor: '#F74545'
      }
      let now = timeFormat(new Date()).slice(11, 13)
      let yest = timeFormat(new Date() - 3600 * 1000 * 24).slice(0, 10)
      if (now >= 10 && now < 13) {
        this.handleGetLuckyDraw(yest)
      }
      //test
      this.handleGetLuckyDraw(yest)

    },

    /*
    查看中奖码
     */
    handleGetLuckyDraw (date) {
      this.$axios({
        url: api.winningCode,
        method: 'get',
        params: {
          date: this.luckyDate || date
        }
      }).then(res => {
        let data = res.data.data,
          now = new Date().getTime()
        this.pubDate = data.codeData.pubTime > now ? 1 : 0
        this.luckyDate = data.codeData.luckyDate ? (timeFormat(data.codeData.luckyDate).slice(0, 10)).replace(/-/g, '') : ''
        this.countDownTime = data.codeData.pubTime - now
        this.drawInfo = data
        this.rewardBall = data.codeData.hasPub ? data.codeData.code.split('') : ''
        this.cityList = data.codeData.codeDataList
      }).catch(err => {
        console.info(err)
      })
    },

    /*
   开奖详情列表按钮事件
    */
    handleRecordClick (item) {
      this.recordId = item.id
      switch (item.status) {
        case 2:
          this.dialogVisible = true
          break
        case 1:
          if (item.codeList.length < item.codeLimit) {
            this.handleWatchVideo(item.id)
          }
          break
        case 3:
          //立即领奖
          this.$h5AppLink('contactUs', {})
          break
        case 6:
          this.$router.push({ name: 'tbCash' })
          break
      }
    },

    /*
    查看视频
    */
    handleWatchVideo (id) {
      this.$h5AppLink('playAdVideo', {
        type: 0,
        bizType: 1,
        bizId: id
      })
    },

    /*
    开奖详情
     */
    handleGetDetail () {
      let yest = timeFormat(new Date() - 3600 * 1000 * 24).slice(0, 10)
      this.$axios(api.luckyDrawDetail, {
        params: {
          date: this.luckyDate || yest
        }
      }).then(res => {
        let data = res.data.data
        this.hasPub = data.hasPub
        this.winningList = data.luckyRecordList
        this.winningGoodsList = data.userLuckyRecordList
        this.loseGoodsList = data.userLosingRecordList
        this.drawRecordList = data.userDrawRecordList
        this.winningGoodsList ? this.winningGoodsList.map(item => {
          item.createTime = item.createTime ? timeFormat(item.createTime).slice(5, 16) : ''
          item.pubTime = item.pubTime ? timeFormat(item.pubTime) : 0
          item.drawTime = item.pubTime.slice(11, 16)
          item.luckyDate = item.luckyDate ? (timeFormat(item.luckyDate).slice(0, 10)).replace(/-/g, '') : ''
        }) : []
        this.loseGoodsList ? this.loseGoodsList.map(item => {
          item.createTime = item.createTime ? timeFormat(item.createTime).slice(5, 16) : ''
          item.pubTime = item.pubTime ? timeFormat(item.pubTime) : 0
          item.drawTime = item.pubTime.slice(11, 16)
          item.luckyDate = item.luckyDate ? (timeFormat(item.luckyDate).slice(0, 10)).replace(/-/g, '') : ''
        }) : []
        this.drawRecordList ? this.drawRecordList.map(item => {
          item.createTime = item.createTime ? timeFormat(item.createTime).slice(5, 16) : ''
          item.pubTime = item.pubTime ? timeFormat(item.pubTime) : 0
          item.drawTime = item.pubTime.slice(11, 16)
          item.luckyDate = item.luckyDate ? (timeFormat(item.luckyDate).slice(0, 10)).replace(/-/g, '') : ''
        }) : []
        this.joinAward = this.winningGoodsList.length == 0 && this.loseGoodsList.length == 0 && this.drawRecordList.length == 0 ? false : true
      }).catch(err => {
        this.isLogin()
        console.info(err)
      })
    },
    /*
    参加活动
    */
    handleJoinActivity () {
      this.$router.push({
        name: 'purchase',
        query: {
          token: localStorage.getItem('token')
        }
      })
    },
    /*
        弹窗按钮提交
         */
    handleDialogSubmit () {
      this.$axios.post(api.recordRefund, {
        id: this.recordId
      }).then(res => {
        this.dialogVisible = false
        console.log(res)
      }).catch(err => {
        console.info(err)
        this.isLogin()
      })
    },
    /*
    关闭弹窗
    */
    handleCloseDialog () {
      this.dialogVisible = false
    },

    /*
    登录验证
     */
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/purchase/award`
      }
      this.$h5AppLink(event, param)
    },
    /*
    头部返回
     */
    goBack () {
      this.$router.go(-1)
    },
    /*
    打开规则页面
     */
    handleShowRule () {
      this.$router.push({ name: 'purchaseRules' })
    }
  }
}
</script>

<style lang="less" scoped>
.award-detail {
  min-height: 100vh;
  background: #F4F4F4;

  .head-active {
    padding-bottom: 35px;
  }

  .award-head {
    position: relative;
    text-align: center;
    width: 375px;
    min-height: 216px;
    background: linear-gradient(180deg, #F74545 0%, #D92635 100%);

    & > img {
      margin-top: 15px;
      width: 350px;
      height: 170px;
    }

    .spread-leave-active, .spread-enter-active {
      transition: all .5s;
      max-height: 83px !important;
    }

    .spread-enter, .spread-leave-to {
      height: 0 !important;
    }

    .show-enter-active, .show-leave-active {
      transition: opacity 1s;
    }

    .show-enter, .show-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .spread-part {
      margin: -10px auto 0;
      width: 350px;
      height: 83px;
      background: #FFF2E4;
      border-radius: 0 0 10px 10px;
      display: flex;

      & > div {
        margin-top: 12px;
        margin-left: 11px;
        padding: 10px 6px;
        width: 26px;
        height: 38px;
        background: #F5DAB6;
        border-radius: 4px;

        p {
          font-size: 12px;
          color: #7F694E;
        }

        p:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .arrow-down {
      width: 100%;
    }

    .van-icon {
      margin-top: 8px;
      font-size: 12px;
      color: #FFFFFF;
    }

    .coupon-inner {
      position: absolute;
      top: 28px;
      left: 20px;

      & > p {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
      }

      .amount {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin-left: 12px;
          font-size: 15px;
          color: #C99040;
          line-height: 21px;
        }

        .van-count-down {
          color: #C99040;
          border: #C99040;
        }

        .colon {
          display: inline-block;
          margin: 0 4px;
          color: #C99040;
        }

        .block {
          display: inline-block;
          width: 30px;
          color: #C99040;
          font-size: 16px;
          border-radius: 4px;
          text-align: center;
          border: 1px solid #C99040;
        }
      }

      .delay-city {
        font-size: 13px;
        color: #333333;
        line-height: 18px;
      }


      .reward-ball {
        display: flex;
        justify-content: center;
        margin: 28px 0 25px;

        & > div {
          position: relative;

          img {
            width: 48px;
            height: 48px;
          }

          p {
            position: absolute;
            top: 5px;
            left: 14px;
            font-size: 30px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 33px;
          }
        }
      }

      .no-pub {
        margin: 28px 0 25px -5px;

        & > img {
          width: 345px;
          height: 52px;
        }
      }
    }

  }

  .award-win {
    position: relative;
    top: -10px;
    text-align: center;

    & > img {
      position: absolute;
      top: -30px;
      left: 64px;
      width: 248px;
      height: 65px;
    }

    .winning-amount {
      position: absolute;
      top: -12px;
      width: 100%;
      font-size: 18px;
      font-weight: 800;
      color: #FFFFFF;
      line-height: 25px;
    }

    .winning-goods-list {
      padding-top: 35px;
      display: inline-block;
      width: 350px;
      background: #FFFFFF;
      border-radius: 10px;
    }

    .purchase-goods-list {
      display: inline-block;
      width: 350px;
      background: #FFFFFF;
      border-radius: 10px;
      margin-bottom: 12px;
    }

    .winning-goods-list,
    .purchase-goods-list {

      .head {
        margin: 14px 20px;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 15px;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
        }

        & > span:last-child {
          color: #FF3B3C;
        }
      }

      .content {
        display: flex;
        margin-left: 15px;

        & > img {
          width: 96px;
          height: 96px;
        }

        .info {
          margin-left: 20px;
          text-align: left;

          .title {
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .title-1 {
            font-size: 15px;
            color: #333333;
            line-height: 21px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .winning {
            margin-top: 11px;
            font-size: 15px;
            font-weight: bold;
            color: #333333;
            line-height: 21px;
          }

          & > p {
            display: flex;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;

            & > span {
              display: block;
              content: " ";
              width: 10px;
            }
          }
        }
      }

      .winning-content {
        margin: 0 7px 10px;
        width: 336px;
        height: 120px;
        background: #F9F9F9;
        border-radius: 10px;
        display: flex;
        align-items: center;

        & > img {
          margin-left: 12px;
        }
      }

      .code-list {
        margin-top: 12px;
        margin-left: 20px;
        padding-bottom: 12px;
        min-width: 310px;
        display: flex;
        border-bottom: 1px solid #E2E2E2;

        & > p {
          width: 70px !important;
          font-size: 12px;
          color: #333333;
          line-height: 20px;
        }

        & > div {
          display: flex;
          flex-wrap: wrap;
          width: 260px;
        }

        .code-wrap {
          margin-left: 8px;
          margin-bottom: 2px;
          width: 74px;
          height: 20px;
          background: #FFFDEA;
          border-radius: 2px;

          & > p {
            text-align: center;
            font-size: 13px;
            color: #C99040;
            line-height: 18px;
          }
        }
      }

      .footer-button {
        float: right;
        margin-top: 10px;
        margin-bottom: 12px;
        margin-right: 14px;
      }

      .button-more {
        width: 107px;
        height: 32px;
        background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
        border-radius: 20px;
        font-size: 15px;
        color: #FFFFFF;
        line-height: 32px;
      }

      .button-earn {
        width: 107px;
        height: 32px;
        background: linear-gradient(180deg, #FF8705 0%, #FE5002 100%);
        border-radius: 20px;
        font-size: 15px;
        color: #FFFFFF;
        line-height: 32px;
      }

      .button-recharge {
        width: 107px;
        height: 32px;
        border-radius: 20px;
        border: 1px solid #FF3B3C;
        font-size: 15px;
        color: #FF3B3C;
        line-height: 32px;
      }
    }

    .award-marked {
      margin: 22px 0 8px 26px;
      text-align: left;
      font-size: 18px;
      font-weight: 800;
      color: #333333;
      line-height: 25px;
    }
  }

  .award-default {
    margin-top: 5px;
    text-align: center;

    & > p {
      text-align: center;
      font-size: 18px;
      font-weight: 800;
      color: #333333;
      line-height: 25px;
    }

    & > div {
      margin: 12px auto 40px;
      display: inline-block;
      width: 350px;
      height: 220px;
      background: #FFFFFF;
      border-radius: 10px;

      & > img {
        margin-top: 24px;
        width: 104px;
        height: 100px;
      }

      .join-activity {
        margin: 20px auto 0;
        width: 260px;
        height: 50px;
        background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
        box-shadow: 0px 2px 8px 0px #FE8D62;
        border-radius: 28px;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 50px;
      }
    }
  }

  .dilate-packet {
    position: relative;
    text-align: center;

    .packet-title {
      width: 160px;
    }

    & > p {
      margin: 0 auto;
      width: 300px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
    }

    .packet-coupon {
      width: 300px;
    }

    .packet-text {
      position: absolute;
      top: 105px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & > p {
        width: 320px;
        font-size: 15px;
        color: #C36202;
        line-height: 21px;
      }
    }

    .packet-button {
      position: absolute;
      left: 46px;
      bottom: 22px;

      .button {
        width: 228px;
        height: 50px;
        background: linear-gradient(180deg, #FFD65C 0%, #FFB500 100%);
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > p {
          font-size: 24px;
          font-weight: bold;
          color: #EC2630;
          line-height: 33px;
        }

        .van-icon {
          font-size: 20px;
          color: #EC2630;
        }
      }

      & > p {
        margin-top: 12px;
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
      }
    }
  }
}
</style>
